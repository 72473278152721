import React, { useState, useEffect } from "react"

const PostAdSense = ({ format = "auto", path }) => {
    const [showAds, setShowAds] = useState(false)

    useEffect(() => {
        if (showAds && window.adsbygoogle) {
            window.adsbygoogle = window.adsbygoogle || []
            window.adsbygoogle.push({})
            // console.log("PostAdSense push")
        }
        if (window && process.env.NODE_ENV !== "development") {
            const minWidth = 768 // Responsive
            const shouldShowAds = window.innerWidth >= minWidth
            if (shouldShowAds) {
                setShowAds(true)
            }
        }
    }, [showAds])
    
    if (!showAds) return null
    return (
        <>
            <div className="h4 text-dark text-center">
                Sponsored Link
            </div>
            <div className="adsense-div text-center">
                <ins
                    className="adsbygoogle"
                    data-ad-client="ca-pub-5551571803618215"
                    data-ad-slot="2586096356"
                    data-ad-format={format}
                    style={{ display: 'block'}}
                />
            </div>
        </>  
    )
}

export default PostAdSense
import React, {useState, useEffect} from "react"
import { FaArrowUp } from "react-icons/fa"


export default function ScrollUp() {
    const [isButtonActive, setIsButtonActive] = useState(false)
    
    useEffect(() => {
        window.addEventListener('scroll', scrollWindow)
        return () => {
            window.removeEventListener('scroll', scrollWindow)
        }
    }, [])

    const scrollWindow = () => {
        const top = 100
        const scroll = window.scrollY
        if (top <= scroll) {
            setIsButtonActive(true)
        } else {
            setIsButtonActive(false)
        }
    }
    // const isButtonActive = true

    const returnTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        })
    }

    return (
        <>
            {isButtonActive && (
                <button
                    onClick={returnTop}
                    className="btn btn-dark rounded-circle position-fixed" 
                    style={{right: "5%", bottom: "5%", zIndex: 2000, minWidth: "48px", minHeight: "48px", paddingBottom: "10px", opacity: "0.9"}}
                >
                <FaArrowUp size={18} />
                </button>
            )}
        </>
    )
}
import React from "react"
import Helmet from "react-helmet"

function Seo({ lang, title, titleTemplate, description, author, image, keywords, meta}) {
    return (
        <Helmet
            htmlAttributes = {{ lang, }}
            title = {title}
            titleTemplate = {`%s | ${titleTemplate}`}
            meta = {[
                {
                    name: `viewport`,
                    content: `width=device-width, initial-scale=1`,
                },
                {
                    name: `description`,
                    content: description,
                },
                {
                    property: `og:title`,
                    content: title,
                },
                {
                    property: `og:description`,
                    content: description,
                },
                {
                    property: `og:type`,
                    content: `website`,
                },
                {
                    property: `og:image`,
                    content: "https://sn-neural-compute.netlify.app/icons/icon-512x512.png",
                },
                {
                    name: `twitter:card`,
                    content: `summary`,
                },
                {
                    name: `twitter:creator`,
                    content: author,
                },
                {
                    name: `twitter:title`,
                    content: title,
                },
                {
                    name: `twitter:description`,
                    content: description,
                },
                {
                    name: `keywords`,
                    content: keywords,
                }
            ].concat(meta)}
        >
            <link rel="preconnect" href="https://fonts.googleapis.com"/>
            <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin/>
            <link href="https://fonts.googleapis.com/css2?family=Raleway&family=Zen+Kaku+Gothic+New:wght@500;700&display=swap" rel="stylesheet" />
        </Helmet>
    )
}

Seo.defaultProps = {
    lang: `ja`,
    title: ``,
    titleTemplate: ``,
    description: ``,
    author: ``, 
    image: ``,
    keywords: [],
    meta: [],
}

export default Seo
import React, { useState } from "react"
import { Link } from "gatsby"

import SearchBox from "./header/searchbox"
import PageLinks from "./header/pageLinks"

function Header ({ siteTitle }) {
    const [toggleOpen, setToggleOpen] = useState(false)
    return(
        <header>
            <nav className="navbar navbar-expand-md navbar-dark" style={{ backgroundColor: "rgba(0, 0, 0, 0.7)"}}>
                <div className="container-fluid">
                    <Link to="/" className="navbar-brand fs-2 fw-bold" id="main-title">{siteTitle}</Link>
                    <button
                        className="navbar-toggler"
                        type="button"
                        onClick={() => setToggleOpen(!toggleOpen)}
                    >
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className={toggleOpen ? "collapse navbar-collapse show" : "collapse navbar-collapse"}>
                        <div className="d-md-none d-block"> 
                            <PageLinks />
                        </div>
                        <div className="me-md-0 ms-md-auto me-5 ms-0">
                            <SearchBox />
                        </div>
                    </div>
                </div>
            </nav>
        </header>
    )
}

export default Header
import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import {
    FaTags
} from "react-icons/fa"

import { getTechTags } from "../../components/techTag"

export default function Topics({ labels }) {
    const data = useStaticQuery(graphql`
        query topicsQuery {
            allMarkdownRemark(
                limit: 50
                sort: { fields: [frontmatter___date], order: DESC }
                filter: { frontmatter: { published: { eq: true } } }
            ) {
                edges {
                    node {
                        frontmatter {
                            tags
                        }
                    }
                }
            }
        }
    `)
    const posts = data.allMarkdownRemark.edges
    const labelCount = labels.map(label => {
        let count = 0;
        posts.forEach(post => {
            if (post.node.frontmatter.tags.includes(label.tag)) {
                count = count + 1
            }
        })
        return [label.tag, count]
    })
    const categories = labelCount.filter(label => {
        return label[1] > 0
    })
    const tags = categories.map(category => {
        return category[0]
    })
    return (
        <>
            <div className="h4 text-dark">
                <FaTags size={26} className=""/> 
                {" "} Topics
            </div>
            <div className="">
                {getTechTags(tags, labels)}
            </div>
        </>
    )
}

Topics.defaultProps = {
    labels: []
}
import React from "react"

import Author from "../../../contents/images/my-icon-brain.png"

const Bio = ({ author, tagline }) => {
    return (
        <>
            <img src={Author} className="img-thumbnail rounded-circle" alt="img-thumbnail" width="100" height="100"/>
            <p className="h2 mt-1">{author}</p>
            <p className="small text-muted">{tagline}</p>
        </>
    )
}

export default Bio
import React, { useState, useEffect } from "react"

const PostAdSenseMobile = ({ format = "auto", path }) => {
    const [showAds, setShowAds] = useState(false)

    useEffect(() => {
        if (showAds && window.adsbygoogle) {
            window.adsbygoogle = window.adsbygoogle || []
            window.adsbygoogle.push({})
            // console.log("PostAdSenseMobile  push")
        }
        if (window && process.env.NODE_ENV !== "development") {
            const minWidth = 768 // Responsive
            const shouldShowAds = window.innerWidth < minWidth
            if (shouldShowAds) {
                setShowAds(true)
            }
        }
    }, [showAds])

    if (!showAds) return null
    return (
        <>
            <div className="h4 text-dark text-center">
                Sponsored Link
            </div>
            <div className="adsense-div text-center">
                <ins
                    className="adsbygoogle"
                    data-ad-client="ca-pub-5551571803618215"
                    data-ad-slot="2431221097"
                    data-ad-format={format}
                    data-full-width-responsive="false"
                    style={{
                        display: "inline-block",
                        maxWidth: "336px",
                        width: "336px",
                        height: "280px",
                        textAlign: "center",
                        overflow: "visible"
                    }}
                />
            </div>
        </>  
    )
}

export default PostAdSenseMobile
import React from "react"
import { navigate } from "gatsby";

const TechTag = ({ tag, title, svg, size, color }) => {
    return (
        <div className="d-inline-block p-1 ps-0">
            <button className="btn btn-dark p-1" onClick={() => navigate(`/${tag}/1`)}>
                <span className="d-inline">{title}</span>
                <span className="d-inline ps-1" style={{ fontSize: size, color: color }}>
                    <svg role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" width={size} height={size} style={{fill: `${color}`}}>
                        <title>{title}</title>
                        <path d={svg} />
                    </svg>
                </span>
            </button>
        </div>
    )
}

TechTag.defaultProps = {
    tag: ``,
    title: ``,
    svg: ``,
    size: 20,
    color: `white`,
}

export const getTechTags = (tags, labels) => {
    const techTags = []
    tags.forEach((tag, i) => {
        labels.forEach((label) => {
            if (tag === label.tag) {
                techTags.push(
                    <TechTag 
                        key={i} 
                        tag={label.tag} 
                        title={label.title} 
                        svg={label.svg} 
                        size={label.size}
                        color={label.color} 
                    />
                )
            }
        })
    })
    return techTags
}
import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import "./bootstrap.min.css"
import "./custom.css"

import Seo from "./seo"
import Header from "./header"
import Footer from "./footer"
import { Sidebar, MobileSidebar } from "./sidebar"

import ScrollUp from "./scrollUp"
import PostAdSense from "./postAdsense"
import PostAdSenseMobile from "./postAdsenseMobile"

const Layout = (props) => {
    const data = useStaticQuery(graphql`
        query siteMetadataQuery {
            site {
                siteMetadata {
                    title
                    tagline
                    description
                    copyright
                    author
                    keywords
                    contacts {
                        linkedin
                        github
                        twitter
                    }
                    labels {
                        tag
                        title
                        svg 
                        size 
                        color
                    }
                }
            }
        }
    `)
    const siteMetadata = data.site.siteMetadata

    const MainContainer = () => {
        // sidebar display none in windowsize <= 768px 
        return(
            <div className="row m-0">
                <div className="col-md-3 d-md-flex d-none">
                    <div className="main m-1 border-end">
                        <Sidebar 
                            author = {siteMetadata.author}
                            tagline = {siteMetadata.tagline}
                            contacts = {siteMetadata.contacts}
                            labels = {siteMetadata.labels}
                            path = {props.path}
                        />
                    </div>
                </div>
                <div className="col-md-9">
                    <div className="main">
                        {props.children}
                    </div>
                    <ScrollUp />
                    {props.adsense ? 
                        <div className="main mb-3">
                            <PostAdSense path={props.path}/>
                            <PostAdSenseMobile path={props.path}/>
                        </div>
                    : null}
                </div>
                <div className="d-md-none d-block border-top">
                    <div className="main border-top">
                        <MobileSidebar 
                            author = {siteMetadata.author}
                            tagline = {siteMetadata.tagline}
                            contacts = {siteMetadata.contacts}
                            labels = {siteMetadata.labels}
                        />
                    </div>
                </div>
            </div>
        )
    }   
    
    return (
        <div className="container mw-100 p-0">
            <Seo
                title = {props.title}
                titleTemplate = {siteMetadata.title}
                description = {props.description || siteMetadata.description}
                author = {siteMetadata.author}
                image = {props.image}
                keywords = {siteMetadata.keywords}
                meta = {props.meta}
            />
            <Header siteTitle = {siteMetadata.title}/>
            <MainContainer />
            <Footer copyright = {siteMetadata.copyright}/>
        </div>
    )
}

Layout.defaultProps = {
    title: ``,
    description: ``,
    image: ``,
    meta: [],
}

export default Layout
import React from "react"
import { FaSearch } from "react-icons/fa"

const SearchBox = () => {
    return (
        <form className="d-flex" id="cse-search-box" action="https://google.com/cse">
            <input type="hidden" name="cx" value="c590de376979504ea" />
            <input type="hidden" name="ie" value="UTF-8" />
            <input className="form-control" type="text" name="q" size="13" placeholder="サイト内検索" />
            <button className="btn btn-outline-light ms-1" type="submit" name="sa" value="検索" aria-label="search-submit">
                <FaSearch size={26} className=""/> 
            </button>
        </form>
    )
}

export default SearchBox
import React from "react"

import Bio from "./sidebar/bio"
import SocialLinks from "./sidebar/socialLinks"
import PageLinks from "./sidebar/pageLinks"
import Topics from "./sidebar/topics"
import Timeline from "./sidebar/timeline"
import Adsense from "./sidebar/adsense"

export function Sidebar({ author, tagline, contacts, labels, path }) {
    return (
        <div className="sidebar mt-1">
            <div className="d-block">
                <Bio author={author} tagline={tagline} />
            </div>
            <div className="d-block">
                <SocialLinks contacts={contacts}/>
            </div>
            <div className="d-block my-1">
                <PageLinks/>
            </div>
            <div className="d-block my-3 me-1">
                <Topics labels={labels}/>
            </div>
            <div className="d-block my-3 me-1">
                <Timeline/>
            </div>
            <div className="d-block my-3 me-1">
                <Adsense path={path}/>
            </div>
        </div>
    )
}

export function MobileSidebar({ author, tagline, contacts, labels }) {
    return (
        <div className="sidebar">
            <div className="d-block my-3">
                <Topics labels={labels}/>
            </div>
            <div className="d-block my-3">
                <Timeline/>
            </div>
        </div>
    )
}
import React from "react"
import {
    FaTwitterSquare
} from "react-icons/fa"

import LazyLoad from "react-lazyload"
import { TwitterTimelineEmbed } from "react-twitter-embed"

const Timeline = () => {
    const PlaceHolder = () => (
        <a className="twitter-timeline" href="https://twitter.com/sn42neuro">
            A Twitter List by sn42neuro
        </a>
    )
    return (
        <>
            <div className="h4 text-dark">
                <FaTwitterSquare size={30} className=""/> 
                {" "} My Twitter
            </div>
            <LazyLoad once placeholder={<PlaceHolder/>}>
                <TwitterTimelineEmbed
                    sourceType="profile"
                    screenName="sn42neuro"
                    noFooter
                    options={{ height: 500, lang: "ja" }}
                />
            </LazyLoad>
        </>
    )
}

export default Timeline
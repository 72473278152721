import React from "react"
import { Link } from "gatsby"

const PageLinks = () => {
    const WrappedLink = ({ title, link }) => (
        <li className="nav-item p-0">
            <div className="nav-link pe-1">
                <Link className="link-light fs-5" to={link}>
                    {title}
                </Link>
            </div>
        </li>
    )
    
    return (
        <ul className="navbar-nav flex-row">
            <WrappedLink title="About" link="/about" />
            <WrappedLink title="Site Map" link="/sitemap" />
            <WrappedLink title="Contact" link="/contact" />
        </ul>
    )
}

export default PageLinks
import React from "react"
import { Link } from "gatsby"

function Footer ({ copyright }) {
    return(
        <footer className="border-top py-3 text-center">
            <span className="">{copyright}</span>{` `}
            <span className="">
                <Link className="link-success" to="/policy">Site Policy</Link>.
            </span>
        </footer>
    )
}

export default Footer
import React from "react"
import { Link } from "gatsby"


const PageLinks = () => {
    const WrappedLink = ({ title, link }) => (
        <li className="nav-item p-0">
            <div className="nav-link p-0">
                <Link className="link-title fs-5" to={link}>
                    {title}
                </Link>
            </div>
        </li>
    )
    
    return (
        <ul className="nav flex-column">
            <WrappedLink title="About" link="/about" />
            <WrappedLink title="Latest Article" link="/" />
            <WrappedLink title="Site Map" link="/sitemap" />
            <WrappedLink title="Bookmarks" link="/link" />
            <WrappedLink title="Contact" link="/contact" />
        </ul>
    )
}

export default PageLinks

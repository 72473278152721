import React from "react"

import {
    FaLinkedin,
    FaGithubSquare,
    FaTwitterSquare
} from "react-icons/fa"

export default function SocialLinks ({ contacts }) {
    const SocialLinksButton = ({title, link, icon}) => (
        <span className="pe-2" title={title}>
            <a className="" href={link} aria-label={title}>
                {icon}
            </a>
        </span>
    )

    return (
        <div className="">
            <SocialLinksButton 
                title = "Linked In" 
                link = {contacts.linkedin}
                icon = {<FaLinkedin size={28} style={{ color: "#0a66c2" }}/>} 
            />
            <SocialLinksButton 
                title = "GitHub" 
                link = {contacts.github}
                icon = {<FaGithubSquare size={28} style={{ color: "dimgray" }}/>} 
            />
            <SocialLinksButton 
                title = "Twitter" 
                link = {contacts.twitter}
                icon = {<FaTwitterSquare size={28} style={{ color: "rgba(29,161,242,1.00)" }}/>} 
            />
        </div>
    )
}
